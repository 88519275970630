import { gql } from '@apollo/client';

export const replyFields = gql`
  fragment replyFields on Reply {
    duration
    id
    text
    transcodedAudioUrl
    createdAt
    user {
      firstName
      avatarUrl
      lastName
      displayName
      twitter
      instagram
      isMembership
    }
    transcribedText
    transcribing
    transcribingIntended
  }
`;

export const TRANSCRIBE_REPLY_MUTATION = gql`
  mutation transcribeReply($replyId: ID!) {
    transcribeReply(replyId: $replyId) {
      success
      error
      reply {
        id
        transcribingIntended
      }
    }
  }
`;

export const REPLY_TRANSCRIBING_SUBSCRIPTION = gql`
  subscription onReplyTranscribing($replyId: ID!) {
    replyTranscribing(replyId: $replyId) {
      reply {
        id
        transcribing
        transcribingIntended
      }
    }
  }
`;

export const REPLY_TRANSCRIBED_SUBSCRIPTION = gql`
  subscription onReplyTranscribed($replyId: ID!) {
    replyTranscribed(replyId: $replyId) {
      reply {
        id
        transcribedText
        transcribing
        transcribingIntended
      }
    }
  }
`;
