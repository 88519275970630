import { gql } from '@apollo/client';

export const notificationFields = gql`
    fragment notificationFields on Notification {
        id
        notificationText
        senderIsFan
        seen
        sender {
            id,
            displayName
            avatarUrl
        }
        sentAgo
    }
`;

export const NOTIFICATIONS_QUERY = gql`
    query notifications($showId: ID, $dashboard: Boolean) {
        notifications(showId: $showId, dashboard: $dashboard) {
            ...notificationFields
        }
    }
    ${notificationFields}
`;

