import { gql } from "@apollo/client";

const pageInfoFields = gql`
  fragment pageInfoFields on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export const chatMessageFields = gql`
  fragment chatMessageFields on ChatMessage {
    id
    content
    kind
    createdAt
    pinned
    liked
    user {
      id
      displayName
      avatarUrl
      bio
      website
      twitter
      instagram
      isMembership
      role
    }
    likesBreakdown {
      id
      displayName
    }
    roleLabel
    parentId
    replyCount
  }
`;

export const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation createChatMessage($content: String!, $showId: ID!, $parentId: ID) {
    createChatMessage(content: $content, showId: $showId, parentId: $parentId) {
      success
      error
    }
  }
`;

export const CHAT_MESSAGES_QUERY = gql`
  query chatMessages($showId: ID!, $parentId: ID, $cursor: String, $first: Int!) {
    chatMessages(showId: $showId, parentId: $parentId, first: $first, after: $cursor) {
      edges {
        node {
          ...chatMessageFields
        }
      }
      pageInfo {
        ...pageInfoFields
      }
    }
  }
  ${chatMessageFields}
  ${pageInfoFields}
`;

export const PINNED_CHAT_MESSAGE = gql`
  query pinnedChatMessage($showId: ID!) {
    pinnedChatMessage(showId: $showId) {
      ...chatMessageFields
    }
  }
  ${chatMessageFields}
`;
export const SUBSCRIPTION_ADD_CHAT_MESSAGE = gql`
  subscription OnChatMessageAdded($showId: ID!) {
    chatMessageAdded(showId: $showId) {
      chatMessage {
        ...chatMessageFields
        parentMessage {
          id
          replyCount
        }
      }
    }
  }
  ${chatMessageFields}
`;

export const DELETE_CHAT_MESSAGE_MUTATION = gql`
  mutation deleteChatMessage($chatMessageId: ID!) {
    deleteChatMessage(chatMessageId: $chatMessageId) {
      success
      error
    }
  }
`;

export const TOGGLE_CHAT_MESSAGE_PIN_MUTATION = gql`
  mutation toggleChatMessagePin($chatMessageId: ID!) {
    toggleChatMessagePin(chatMessageId: $chatMessageId) {
      success
      error
      chatMessage {
        id
        pinned
      }
      formerPinMessage {
        id
        pinned
      }
    }
  }
`;

export const SUBSCRIPTION_DELETE_CHAT_MESSAGE = gql`
  subscription OnChatMessageRemoved($showId: ID!) {
    chatMessageRemoved(showId: $showId) {
      id
      parentMessage {
        id
        replyCount
      }
    }
  }
`;

export const VIEWER_HAS_CHAT_ACCESS = gql`
  query publicShow($slug: String, $uuid: String) {
    publicShow(slug: $slug, uuid: $uuid) {
      id
      viewerHasChatAccess
    }
  }
`

export const TOGGLE_CHAT_MESSAGE_LIKE_MUTATION = gql`
  mutation toggleChatMessageLike($chatMessageId: ID!) {
    toggleChatMessageLike(chatMessageId: $chatMessageId) {
      success
      error
      chatMessage {
        id
        liked
        likesBreakdown {
          id
          displayName
        }
      }
    }
  }
`;

export const PUBLIC_SHOW_CHAT_CONTACTS_QUERY = gql`
    query publicShowChatContacts($showId: ID!) {
        publicShowChatContacts(showId: $showId) {
          id
          displayName
          avatarUrl
        }
    }
`;

export const PUBLIC_SHOW_CHAT_CONTACT_QUERY = gql`
  query publicShowChatContact($contactId: ID!) {
      publicShowChatContact(contactId: $contactId) {
        id
        displayName
        avatarUrl
        addedAt
        adminRole
        bio
        instagram
        superfan
        themeColor
        twitter
        website
      }
  }
`;

export const CHAT_MESSAGE_QUERY = gql`
  query chatMessage($id: ID!) {
    chatMessage(id: $id) {
      ...chatMessageFields
    }
  }
  ${chatMessageFields}
`;
