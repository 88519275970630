import React from "react";
import styled from "styled-components";
import { Svg } from "atoms";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faTrashAlt,
  faFolder,
  faEye,
  faCopy,
  faEnvelope,
  faCommentAlt,
  faAddressBook,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCheck,
  faExternalLinkAlt,
  faPause,
  faPlay,
  faVolumeDown,
  faSortAmountDownAlt,
  faDownload,
  faFont,
  faMicrophone,
  faBolt,
  faChevronRight,
  faChevronLeft,
  faEllipsisV,
  faReply,
  faShareAlt,
  faTag,
  faThumbtack,
  faVideo,
  faUpload,
  faCog,
  faPencilAlt,
  faSave,
  faTimes,
  faGripVertical,
  faBell,
  faEnvelopeOpenText,
  faDesktop,
  faPodcast,
  faRss,
  faStar,
  faSignOutAlt,
  faEnvelope as faEnvelopeSolid,
  faEyeSlash,
  faDollarSign,
  faEllipsisH,
  faCloudUploadAlt,
  faLock,
  faUnlock,
  faQuestionCircle,
  faPlus,
  faMicrophoneAlt,
  faComments,
  faPaperPlane,
  faFlag,
  faHeart as faSolidHeart,
  faFilter,
  faSearch,
  faUndo,
  faLink,
  faHeadphones,
  faCheckCircle,
  faCheckSquare,
  faAngleDown,
  faAngleUp,
  faCreditCard,
  faInfoCircle,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { getColorValue } from "ai";
import {
  faFacebook,
  faFacebookF,
  faGoogle,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faSpotify,
  faTiktok,
  faTwitter,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

export const CloseIcon = ({ size, ...props }) => {
  return (
    <Svg style={{ width: size, height: size }} {...props}>
      <path
        d="M13.707.293a.999.999 0 0 0-1.414 0L7 5.586 1.707.293A.999.999 0 1 0 .293 1.707L5.586 7 .293 12.293a.999.999 0 1 0 1.414 1.414L7 8.414l5.293 5.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L8.414 7l5.293-5.293a.999.999 0 0 0 0-1.414"
        fill="currentColor"
        fillRule="evenodd"
      ></path>
    </Svg>
  );
};
CloseIcon.defaultProps = {
  size: "14px",
};

export const CircleCheck = ({ size, color = 'currentColor', ...props }) => {
  return (
    <Svg height={size} width={size} viewBox={`0 0 512 512`} {...props}>
      <path
        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
        fill={color}
        fillRule="evenodd"
      ></path>
    </Svg>
  );
};

export const FaMessage = ({ size, ...props }) => {
  return (
    <Svg height={size} width={size} viewBox={`0 0 512 512`} {...props}>
      <path 
        d="M160 368c26.5 0 48 21.5 48 48v16l72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6H448c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16V352c0 8.8 7.2 16 16 16h96zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V474.7v-6.4V468v-4V416H112 64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L208 492z"
        fill="currentColor"
        fillRule="evenodd"
        ></path>
    </Svg>
  );
};

FaMessage.defaultProps = {
  size: "14px",
};

CircleCheck.defaultProps = {
  size: "14px",
};

const PodFontAwesomeIcon = styled(({ verticalMiddle, block, ...props }) => (
  <FontAwesomeIcon {...props} />
))`
  ${(props) => props.block && `display: block;`}

  ${(props) =>
    props.color &&
    `
        color: ${getColorValue(props.color)};
    `}
  ${(props) =>
    props.verticalMiddle &&
    `
        vertical-align: middle;
    `}
  ${(props) => props.css}
`;

export const FaFolder = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faFolder} {...props} />
);

export const FaAngleLeft = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faAngleLeft} {...props} />
);

export const FaCircleInfo = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faInfoCircle} {...props} />
);

export const FaAngleDown = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faAngleDown} {...props} />
);

export const FaAngleUp = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faAngleUp} {...props} />
);

export const FaSortAmountDownAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faSortAmountDownAlt} {...props} />
);

export const FaDownload = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faDownload} {...props} />
);

export const FaCheck = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCheck} {...props} />
);

export const FaTrashAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faTrashAlt} {...props} />
);

export const FaExtraLinkAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faExternalLinkAlt} {...props} />
);

export const FaPlay = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faPlay} {...props} />
);

export const FaPause = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faPause} {...props} />
);

export const FaHeart = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faHeart} {...props} />
);

export const FaSHeart = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faSolidHeart} {...props} />
);

export const FaVolumeDown = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faVolumeDown} {...props} />
);

export const FaTwitter = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faTwitter} {...props} />
);

export const FaXTwitter = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faXTwitter} {...props} />
);

export const FaInstagram = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faInstagram} {...props} />
);

export const FaFont = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faFont} {...props} />
);

export const FaMicrophone = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faMicrophone} {...props} />
);
export const FaMicrophoneAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faMicrophoneAlt} {...props} />
);

export const FaBolt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faBolt} {...props} />
);

export const FaChevronRight = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faChevronRight} {...props} />
);

export const FaChevronLeft = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faChevronLeft} {...props} />
);

export const FaEllipsisV = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEllipsisV} {...props} />
);

export const FaReply = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faReply} {...props} />
);

export const FaShareAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faShareAlt} {...props} />
);

export const FaTag = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faTag} {...props} />
);

export const FaThumbtack = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faThumbtack} {...props} />
);

export const FaEye = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEye} {...props} />
);

export const FaEyeSlash = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEyeSlash} {...props} />
);

export const FaCopy = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCopy} {...props} />
);

export const FaVideo = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faVideo} {...props} />
);

export const FaUpload = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faUpload} {...props} />
);

export const FaCog = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCog} {...props} />
);

export const FaPencilAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faPencilAlt} {...props} />
);

export const FaSave = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faSave} {...props} />
);

export const FaTimes = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faTimes} {...props} />
);

export const FaGripVertical = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faGripVertical} {...props} />
);

export const FaBell = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faBell} {...props} />
);

export const FaEnvelope = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEnvelope} {...props} />
);

export const FaEnvelopeSolid = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEnvelopeSolid} {...props} />
);

export const FaEnvelopeOpenText = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEnvelopeOpenText} {...props} />
);

export const FaDesktop = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faDesktop} {...props} />
);

export const FaFacebookF = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faFacebookF} {...props} />
);

export const FaLinkedinIn = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faLinkedinIn} {...props} />
);

export const FaTikTok = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faTiktok} {...props} />
);

export const FaYoutube = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faYoutube} {...props} />
);

export const FaPodcast = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faPodcast} {...props} />
);

export const FaSpotify = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faSpotify} {...props} />
);

export const FaGoogle = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faGoogle} {...props} />
);

export const FaRss = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faRss} {...props} />
);

export const FaStar = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faStar} {...props} />
);

export const FaSignOutAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faSignOutAlt} {...props} />
);

export const FaUserFriends = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faUserFriends} {...props} />
);

export const FaCommentAlt = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCommentAlt} {...props} />
);

export const FaDollarSign = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faDollarSign} {...props} />
);

export const FaEllipsisH = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faEllipsisH} {...props} />
);

export const FaAddressBook = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faAddressBook} {...props} />
);

export const FaCloudUpload = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCloudUploadAlt} {...props} />
);

export const FaLock = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faLock} {...props} />
);

export const FaUnLock = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faUnlock} {...props} />
);

export const FaQuestionCicle = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faQuestionCircle} {...props} />
);

export const FaPlus = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faPlus} {...props} />
);

export const FaPaperPlane = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faPaperPlane} {...props} />
);

export const FaComments = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faComments} {...props} />
);

export const FaFlag = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faFlag} {...props} />
);

export const FaFilter = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faFilter} {...props} />
);

export const FaSearch = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faSearch} {...props} />
);

export const FaUndo = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faUndo} {...props} />
);
export const FaLink = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faLink} {...props} />
);
export const FaHeadphones = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faHeadphones} {...props} />
);
export const FaCheckCircle = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCheckCircle} {...props} />
);
export const FaCheckSquare = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCheckSquare} {...props} />
);
export const FaCreditCard = ({ ...props }) => (
  <PodFontAwesomeIcon icon={faCreditCard} {...props} />
);
