import { gql } from '@apollo/client';
export * from "./fan";

export * from "./fragments";

export * from './message';

export * from './payment'

export * from './reward';

export * from './inbox';

export * from './reply';

export {
  notificationFields,
  NOTIFICATIONS_QUERY
} from './notification';

export {
  TierFields
} from './show';

export * from './chat';

export {
  PERK_TEMPLATES_QUERY,
  perkTemplateFields,
  publicPerkFields
} from './perk';

export const PUBLIC_PAGE_SLUG_VALIDATOR_QUERY = gql`
  query publicPageSlugValidator($slug: String!) {
    publicPageSlugValidator(slug: $slug) {
      success,
      error
    }
  }
`;

export const WIDGET_ME_QUERY = gql`
  query WidgetMe($showSlug: String) {
    widgetMe {
      id,
      firstName,
      lastName,
      displayName,
      email,
      twitter,
      instagram,
      avatarUrl,
      isConfirmed,
      reachedMaxDailyMessagesCount(showSlug: $showSlug)
      viewerSuspendedPosting(showSlug: $showSlug)
    }
  }
`;

export const ME_QUERY = gql`
  query Me {
    me {
      id
      firstName
      lastName
      displayName
      isAdmin
      bio
      email
      twitter
      instagram
      website
      lockedUntil
      avatarUrl
      showCancelledBanner
      stripeActiveSince
      type
      freemium
      isConfirmed
      showWelcomeTour
      hasUnseenNotifications
      showShowsTab
      maxShowsCount
      allowNewShows
      isMembership
      company {
        id
        status
      }
      companyShows {
        id
        coverImageUrl
        title
        active
      }
      viewingShow {
        id
      }
    }
  }
`;

export const ME_UNSEEN_NOTIFICATIONS_COUNT_QUERY = gql`
  query MeUnseenNotificationsCount($dashboard: Boolean, $showId: ID) {
    meUnseenNotificationsCount(dashboard: $dashboard, showId: $showId)
  }
`;

export const SEND_EMAIL_CONFIRMATION_MUTATION = gql`
  mutation sendEmailConfirmation {
    sendEmailConfirmation {
      success,
      error
    }
  }
`;

export const INBOX_QUERY = gql`
  query inbox {
    inbox {
      id,
      name,
      description,
      prompt,
      default,
      welcomeVideoActive,
      welcomeVideoThumbnailUrl,
      welcomeVideoUrl,
      welcomeVideoCreatedDate,
      welcomeVideoDuration
    }
  }
`;

export const HIDE_WELCOME_TOUR_MUTATION = gql`
  mutation hideWelcomeTour {
    hideWelcomeTour {
      success,
      error
    }
  }
`;

export const GENERAL_TIPS_THANK_YOU = gql`
  query generalTipsThankYou($showId: ID!) {
    generalTipsThankYou(showId: $showId)
  }
`;

export const MESSAGE_TIPS_THANK_YOU = gql`
  query messageTipsThankYou($showId: ID!) {
    messageTipsThankYou(showId: $showId)
  }
`;
