import { gql } from "@apollo/client";

export const perkTemplateFields = gql`
  fragment perkTemplateFields on PerkTemplate {
    id
    title
    description
    unlockedDescription
    status
    coverImageUrl
    priority
    summary
    amountCents
    showClaimButton
    claimButtonLabel
    claimButtonLink
    customFieldLabel
    customFieldDescription
    customFieldRequired
    customFieldEnabled
  }
`;

export const PERK_TEMPLATES_QUERY = gql`
  query perkTemplates {
    perkTemplates {
      ...perkTemplateFields
    }
  }
  ${perkTemplateFields}
`;

export const publicPerkFields = gql`
  fragment publicPerkFields on PublicPerk {
    id
    uuid
    coverImageUrl
    title
    summary
    description
    amountCents
    unlockedDescription
    status
    unlocked
    unlockedAt
    purchasesNumber
    showClaimButton
    claimButtonLabel
    claimButtonLink
    purchasedPrice
    customFieldLabel
    customFieldDescription
    customFieldRequired
    customFieldEnabled
  }
`;
